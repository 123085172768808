var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Co Branding Permintaan Material")]),_c('a-date-picker',{staticClass:"ml-3 mr-2",staticStyle:{"width":"150px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY"},on:{"change":_vm.filterDate},model:{value:(_vm.tgl1),callback:function ($$v) {_vm.tgl1=$$v},expression:"tgl1"}}),_c('a-button-group',[_c('a-button',{staticClass:"text-primary",attrs:{"icon":"plus-circle","type":""},on:{"click":function($event){return _vm.sendToCreate('create', {})}}},[_vm._v(" Tambah ")]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Refresh Data")])]),_c('a-button',{on:{"click":function($event){return _vm.getAllData(true)}}},[_c('a-icon',{staticClass:"text-success",attrs:{"type":"sync","spin":_vm.loading}})],1)],2)],1)],1),_c('a-table',{staticStyle:{"margin":"-25px -25px"},attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":{
      hideOnSinglePage: true,
      defaultPageSize: 10,
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (total) => `Total ${total} items`,
      pageSizeOptions: ['10', '20', '30'],
    },"size":"small","loading":_vm.loading},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
            .toString()
            .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"text-warning",on:{"click":function($event){return _vm.sendToEdit(record)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}},{key:"tgl",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(record.tgl))+" ")])}},{key:"expandedRowRender",fn:function(record){return [_c('a-table',{attrs:{"columns":_vm.columnChildren,"dataSource":record.details,"size":"small","pagination":{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }},scopedSlots:_vm._u([{key:"formatCurrency",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}}],null,true)})]}}])})],2),_c('a-modal',{attrs:{"visible":_vm.amodalvisible,"title":'Form ' + _vm.action,"dialogStyle":{ top: '10px' }}},[_c('ag-grid-vue',{key:_vm.componentKey,class:_vm.settings.theme === 'dark'
            ? 'ag-theme-balham-dark mt-2'
            : 'ag-theme-balham mt-2',staticStyle:{"height":"41vh"},attrs:{"gridOptions":_vm.gridOptions,"columnDefs":_vm.columnDefs,"pagination":false,"accentedSort":false,"sortingOrder":['asc', 'desc'],"rowData":_vm.rowData,"singleClickEdit":false,"undoRedoCellEditing":true,"undoRedoCellEditingLimit":5,"rowSelection":"single","frameworkComponents":_vm.frameworkComponents},on:{"grid-ready":_vm.onGridReady,"first-data-rendered":_vm.onFirstDataRendered,"selection-changed":_vm.onSelectionChanged,"cell-value-changed":_vm.onCellValueChanged}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }